import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { environment } from '../../../../../../environments/environment';
import * as XLSX from 'xlsx';
@Injectable({providedIn:'root'})
export class SdService {
  api_url=environment.API_URL+'sd/';
  statusTypes=[{id:'enabled',value:'Live'},{id:'paused',value:'Pause'},{id:'archieved',value:'Out Of Budget'}];
  targetTypes=[{id:'automatic',value:'Automatic'},{id:'manual',value:'Manual'}];
  marketplace_id=0;
  constructor(private http:HttpClient,private router:Router) { }
getParams()
{
  let fragments=this.router.url.split('/');
  return {brand_id:fragments[3],service_id:fragments[5]}
}
  campaign={
    getCampaigns:(data)=>{
      const {brand_id,service_id}=this.getParams();
      const query_string=`brand_id=${brand_id}&marketplace_id=${data.marketplace_id}&status=${data.status}&page=${data.page}&start_date=${data.start_date}&end_date=${data.end_date}&search_param=${data.search_param}&page_size=${data.page_size || 10}`;
      return this.http.get(this.api_url+'display_campaigns'+ `?${query_string}`);
    }
  };
  ad_group={
    getAdGroups:(data)=>{
      const {brand_id,service_id}=this.getParams();
      const query_string=`brand_id=${brand_id}&marketplace_id=${data.marketplace_id}&status=${data.status}&page=${data.page}&start_date=${data.start_date}&end_date=${data.end_date}&search_param=${data.search_param}&page_size=${data.page_size || 10}`;
      return this.http.get(this.api_url+'display_adgroups'+ `?${query_string}`);
    }
  };
  Target={
    getTargets:(data)=>{
      const {brand_id,service_id}=this.getParams();
      return this.http.get(this.api_url+'display_targets'+ `?brand_id=${brand_id}&service_id=${service_id}&marketplace_id=${this.marketplace_id}`);
    }
  }
   helper={
    getACOS:(spends,sales)=>{
return sales>0?spends/sales*100:0;
    },
    getROAS:(sales,spends)=>{
      return sales/spends;
          }
  }
  downloadCSV(data,name) {
   
    if(!data || !data.length)
      return;
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'name');
    XLSX.writeFile(wb, `${name}.xlsx`)
  }
}
