import { NgModule} from '@angular/core';

import { DndDirective } from './upload.driective';


@NgModule({
  declarations: [
    DndDirective
  ],
  exports: [
    DndDirective
  ]
})




export class DndDirectiveModule {}
