import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { getServiceByStoreId } from 'app/shared/commons/service-url';
import { DashboardService } from 'app/shared/services/dashboard.service';

import { SharedService } from '../services/shared.service';

const tabs = 
[
  {
  label: 'Dashboard',
  link: './dashboard',
  index: 0
},
 {
  label: 'Sponsored Products',
  link: './sponsored-products',
  index: 0
}, {
  label: 'Sponsored Brands',
  link: './sponsored-brands',
  index: 1
}, {
  label: 'Sponsored Display',
  link: './sponsored-display',
  index: 2
},
//  {
//   label: 'Portfolios',
//   link: '../portfolios',
//   index: 3
// }
]
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {
  navLinks = tabs;
  selected_integration = null;

  constructor(private service: SharedService, private dashboardService: DashboardService, private route: ActivatedRoute) { }
  integrations = [];
  ngOnInit(): void {
    this.service.header_tabs_status.next(true);
    // if (this.service.brandData && this.service.brandData.brand_id == this.route.snapshot.params['brandId'] && this.service.brandData.service_id == this.route.snapshot.params['service_id']) {
    //   this.integrations = this.service.brandData.integrations;
    //   this.selected_integration = this.integrations[0];
    // }
    // else { this.getServicesByStoreId(this.route.snapshot.params['brandId'], this.route.snapshot.params['service_id']) }
  }
  ngOnDestroy() {
    this.service.header_tabs_status.next(false);

  }
  // getServicesByStoreId(storeId, serviceId) {

  //   this.dashboardService
  //     .getServiceByStoreId(storeId)
  //     .subscribe((data) => {
  //       if (data) {
  //         const { response_body } = data;
  //         console.log(response_body)
  //         if (response_body?.services?.length) {
  //           response_body.services.forEach(e => {
  //             if (e.id == serviceId) {
  //               this.service.brandData = { brand_id: storeId, service_id: serviceId, integrations: e.integrations };
  //               this.integrations = e.integrations;
  //               this.selected_integration = this.integrations[0];
  //               return;
  //             }
  //           })
  //         }
  //       }
  //     });
  // }

  changeMarketplace(data)
  {
    console.log(data)
  }
  setRoute(name) {
    return name;
  }


}
